import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Statics from './pages/Statics';
import Upload from './pages/Upload';
import PurchasePage from './pages/PurchasePage';
import CheckoutForm from './Components/CheckoutForm';
import Return from './Components/Return';
import CheckoutButton from './Components/CheckoutButton';


function App() {
  return (
    <Router>
      <div>
        
        <Routes>
          <Route path="/" element={<Upload />} />
          <Route path="/upload"  element={<Upload />} />
          <Route path="/stat" element={<Statics />} />
          <Route path="/golfshot/:customerId" element={<PurchasePage />} />
          <Route path="/checkout/:id" element={<CheckoutForm />} />
          <Route path="/return" element={<Return />} />
          <Route path="/button" element={<CheckoutButton />} />


        </Routes>
        
      </div>
    </Router>

  );
}

export default App;
