// CharityCard.js
import React from 'react';
import './CharityCard.css'; // Importing CSS file for styling

import imageFile from './ch.jpg';
const CharityCard = ({ name, image, description }) => {
    //console.log(image);
  return (
    <div className="charity-card">
      <h2>{name}</h2>
      <div className='charity-image-divider'>
        <img src={imageFile} alt={name} className="charity-image" />
        <p className='charity-item'>{description}</p>
      </div>
      
    </div>
  );
};

export default CharityCard;
