import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [open, setOpen] = useState(null);

  const handleToggle = (index) => {
    if (open === index) {
      setOpen(null);
    } else {
      setOpen(index);
    }
  };

  const faqData = [
    {
      question: "I'm having difficulty with my download?",
      answer: "If you’re having difficulty with your download, it could be a problem with your browser selection or settings. You’ll want to make sure that your browser software is up to date or try switching browsers entirely as sometimes older versions of certain browsers can interfere with download. Our platform tends to work best on Google Chrome. Please note that our download and checkout does not support Internet Explorer. Additionally, we’d recommend clearing your browser cache and cookies. If this does not solve your problem, please contact us and we’ll try to help.",
    },
    {
      question: "Will I get a confirmation of my donation?",
      answer: "You will be emailed a confirmation receipt of your donation immediately after downloading your golf shot.",
    },
    {
      question: "Why isn't my donation going through?",
      answer: "If you receive a “failed donation” message indicating your card was declined or that the CVV code was entered incorrectly, please go back and try again! If it fails to process a second time, there are a couple of things we recommend to troubleshoot. You’ll want to make sure that your browser software is up to date or try switching browsers entirely as sometimes older versions of certain browsers can interfere with checkout. Our platform tends to work best on Google Chrome. Please note that our checkout does not support Internet Explorer. Additionally, we’d recommend clearing your browser cache and cookies. If you need any further assistance, please contact us directly via email.",
    },
    {
      question: "What do you do with my information and credit card info?",
      answer: "Privacy comes first and foremost when fundraising on a golf course. First, we have a few fundamental principles:\n⦁ We won’t ask you for personal information unless we truly need it to provide our services.\n⦁ We won’t share your personal information with anyone except to comply with the law, develop new products, provide our services, or protect our rights.\n⦁ We don’t store personal information on our servers unless it’s required by law or for the on-going operation of one of our services. (See Privacy Policy)",
    },
    {
      question: "How is my information kept safe and secure?",
      answer: "The security of your information and transactions is one of our highest priorities. Your information is automatically encrypted in transit using the Secure Sockets Layer protocol (SSL) with an encryption key length of 256 bits (the highest level commercially available). Our servers sit behind an electronic firewall and are never directly connected to the Internet, so your private information stays private.",
    },
    {
      question: "Is my donation secure on your checkout?",
      answer: "We provide a highly secure, robust, and reliable third-party payment processing service. Upon a download, donation details are transmitted to our third-party payments partner. We use Stripe and PayPal. Both merchant systems are Level 1 PCI Compliant ensuring the highest level of security and compliance.",
    }
  ];

  return (
    <div className='FAQContainer'>
      {faqData.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => handleToggle(index)}>
            {item.question}
            <span className="toggle-icon">{open === index ? '-' : '+'}</span>
          </div>
          {open === index && <div className="faq-answer">{item.answer}</div>}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
