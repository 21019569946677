import React, { useState, useCallback} from 'react';
import './Upload.css'; // Import your CSS file
import { useDropzone } from 'react-dropzone';
import axios from'axios';
import AWS from 'aws-sdk'
import {accessKeyId,secretAccessKey} from './pass.js';

const S3_BUCKET ='videos-for-golf';
const REGION ='us-east-2';

AWS.config.update({
  accessKeyId: accessKeyId/*process.env.AWS_ACCESS_KEY_ID*/,
  secretAccessKey: secretAccessKey/*process.env.AWS_SECRET_ACCESS_KEY*/
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET},
  region: REGION,
})

function Upload() {
  const [selectedDropbox, setSelectedDropbox] = useState('');
  const [selectedDropboxCity, setSelectedDropboxCity] = useState('');

  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [progress1 , setProgress1] = useState(0);
  const [progress2 , setProgress2] = useState(0);


  const [video1,setVideo1] = useState(null);
  const [video2,setVideo2] = useState(null);

  const handleDropboxSelect = (event) => {
    setSelectedDropbox(event.target.value);
  };
  const handleDropboxSelectCity = (event) => {
    setSelectedDropboxCity(event.target.value);
  };

  const handlePaymentStatusChange = (event) => {
    setIsPaymentDone(event.target.checked);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };


  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the accepted files
    console.log('Accepted Files:::', acceptedFiles);
    setVideo1(acceptedFiles[0]);
    setVideo2(acceptedFiles[1]);


  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const uploadFile = () => {


    const modifiedFileName1 = video1.name.replace(/ /g, '+');
    const modifiedFileName2 = video2.name.replace(/ /g, '+');

    let url1 = 'https://videos-for-golf.s3.us-east-2.amazonaws.com/'+modifiedFileName1;
    let url2 = 'https://videos-for-golf.s3.us-east-2.amazonaws.com/'+modifiedFileName2;
    console.log(url1);

    const apiUrl = 'https://golf-backend.vercel.app/customers'; // Replace with your API endpoint

    const newCustomer = {
      telephoneNumber: phoneNumber,
      paymentInfo: isPaymentDone,
      state: selectedDropbox,
      city: selectedDropboxCity,
      videoURL1: url1,
      videoURL2: url2,
    };

    axios.post(apiUrl, newCustomer)
      .then(response => {
        console.log('Customer added successfully:', response.data);
      })
      .catch(error => {
        console.error('Error adding customer:', error);
      });

    axios.post('https://golf-backend.vercel.app/send-sms', {
      phoneNumber:"12346789",
      urlKey:url1
    })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.error(error);
    });
    
    const params1 = {
        ACL: 'public-read',
        Body: video1,
        Bucket: S3_BUCKET,
        Key: video1.name
    };
    const params2 = {
      ACL: 'public-read',
      Body: video2,
      Bucket: S3_BUCKET,
      Key: video2.name
  };

    myBucket.putObject(params1)
    .on('httpUploadProgress', (evt) => {
        setProgress1(Math.round((evt.loaded / evt.total) * 100))
    })
    .send((err) => {
        if (err) console.log(err)
    })

    myBucket.putObject(params2)
    .on('httpUploadProgress', (evt) => {
        setProgress2(Math.round((evt.loaded / evt.total) * 100))
    })
    .send((err) => {
        if (err) console.log(err)
    })

  }



  return (
    <div className="upload-container">
      <div className="upload-form">
        <div {...getRootProps()} style={dropzoneStyles}>
          <input {...getInputProps()} />
          <p>Drag & drop a file here, or click to select one</p>
        </div>
        
        <label className="form-label">
          State
          <br/>
          <select
            value={selectedDropbox}
            onChange={handleDropboxSelect}
            className="form-input"
          >
            <option value="">Select a Dropbox</option>
            <option value="A">Option A</option>
            <option value="B">Option B</option>
            <option value="C">Option C</option>
          </select>
        </label>
        <label className="form-label">
          City
          <br/>
          <select
            value={selectedDropboxCity}
            onChange={handleDropboxSelectCity}
            className="form-input"
          >
            <option value="">Select a Dropbox</option>
            <option value="A">Option A</option>
            <option value="B">Option B</option>
            <option value="C">Option C</option>
          </select>
        </label>
        <br/>
        <label className="form-label">
          Up Front Payment Done
          <input
            type="checkbox"
            checked={isPaymentDone}
            onChange={handlePaymentStatusChange}
            className="form-input"
          />
        </label>
        <br />
        <label className="form-label">
          Telephone Number
          <input
            type="tel"
            placeholder="Telephone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className="form-input"
          />
        </label>
        

        <div>
          <div>Native SDK File Upload Progress Video 1 is {progress1}%</div>
          <div>Native SDK File Upload Progress Video 2 is {progress2}%</div>
          <button onClick={() => uploadFile()}> Upload to S3</button>
      </div>

    

      </div>
    </div>
  );
}

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default Upload;


/*

<label className="form-label">
          File Upload
          <input
            type="file"
            onChange={handleFileUpload}
            className="form-input"
          />
        </label>

<button onClick={handleSubmit} className="submit-button">
          Submit
        </button>

<div>
      <h2>Video Player</h2>
      <video width="640" height="360" ref={videoRef}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button onClick={handlePlay}>Play 5 Seconds</button>
    </div>

*/