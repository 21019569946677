import React from 'react';
import './TestimonialStyle.css';
import Mich from './Michael.PNG';
import jk from './Jack.PNG';




const TestimonialSection = () => {
  return (
    <div className="testimonial-section">
      <div className="testimonial">
       <img src={Mich} alt="Testimonial Author" className="testimonial-image" />
        <p className="testimonial-text">{"I'm so glad the team got my shot on camera. It's a great memory to me and my family forever and plus it's for a good cause"}</p>
        <p className="testimonial-author">- {"Michael"}</p>
      </div>
      <div className="testimonial">
      <img src={jk} alt="Testimonial Author" className="testimonial-image" />
        <p className="testimonial-text">{"Glad the team captured my shot. It's valuable to me and something that I can look back on forever and I'm glad I can contribute to a great cause"}</p>
        <p className="testimonial-author">- {"Jake"}</p>
      </div>

    </div>
  );
};

export default TestimonialSection;
