import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';

import "./Purchase.css";
import axios from "axios";
import CharityCard from "../Components/CharityCard";
import CountdownTimer from "../Components/CountdownTimer";
import FAQ from "../Components/FAQ";
import {
  BrowserRouter as Router,
  Navigate
} from "react-router-dom";
import TestimonialSection from "./TestimonialSection ";


function PurchasePage() {
  const [isChecked, setIsChecked] = useState(false);
  const { customerId } = useParams();
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [date, setDate] = useState("");
  const [formatedDate, setFormatedDate] = useState("");

  const [subscription, setSubscription] = useState(false);
  const [payment, setPayment] = useState(false);

  let videoRef1 = useRef(null);
  let videoRef2 = useRef(null);

  // Define states for Full Name, Mail, and Address
  const [fullName, setFullName] = useState("");
  const [mail, setMail] = useState("");
  const [address, setAddress] = useState("");

  const charityData = [
    {
      name: "Your support will go towards helping First Tee",
      image: "CH1.jpg",
      description:
        "First Tee is a youth development organization that enables kids to build the strength of character that empowers them through a lifetime of new challenges.",
    },
    {
      name: "Charity B",
      image: "charity_b.jpg",
      description: "Description of Charity B",
    },
    // Add more charities as needed
  ];

  const getCustomerDetails = async (id) => {
    try {
      const response = await axios.get(
        `https://golf-backend.vercel.app/customers/${id}/details`,
      );

      return response.data.customerDetails;
    } catch (error) {
      console.error("Error fetching customer details:", error);
      throw error;
    }
  };

  useEffect(() => {
    document.title = 'Golf Shot'; 


      axios
      .get(`https://golf-backend.vercel.app/customers/${customerId}`)
      .then((response) => {
        const { city, state,videourl1, videourl2,registrationdate,name,paymentinfo } = response.data;
        setCity(city);
        setState(state);
        setUrl1(videourl1);
        setUrl2(videourl2);
        const registrationDate = new Date(registrationdate);
        const formattedDate = registrationDate.toISOString().split("T")[0];
        setFormatedDate(formattedDate);

        if (name != undefined) {
          setSubscription(true);
        }
        if (paymentinfo) {
          setPayment(true);
        }

        console.log("Video URL 1:", videourl1);
        console.log("Video URL 2:", videourl2);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching city and state:", error);
      });

  }, []);

  const updateCustomer = async (id, mail, name, address) => {
    try {
      const response = await axios.put(
        `https://golf-backend.vercel.app/customers/${id}`,
        {
          mail,
          name,
          address,
        },
      );

      return response.data;
    } catch (error) {
      console.error("Error updating customer:", error);
      throw error;
    }
  };

  const handlePlay1 = () => {
    videoRef1.current.currentTime = 0; // Start from the beginning
    videoRef1.current.play();
    setTimeout(() => {
      videoRef1.current.pause();
    }, 5000); // Pause after 5 seconds
  };

  const handlePlay2 = () => {
    videoRef2.current.currentTime = 0; // Start from the beginning
    videoRef2.current.play();
    setTimeout(() => {
      videoRef2.current.pause();
    }, 5000); // Pause after 5 seconds
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleDownloadClick = async () => {
    if (isChecked) {
      // Perform download action
      let result = await updateCustomer(customerId, mail, fullName, address);
      if (result.status == 404 || result.status == 500) {
        alert("Please Fill the Subscription area Informations.");
      } else {
        alert("Successfully subscribed");
        setSubscription(true);
      }
    } else {
      alert("Please read and accept the terms before downloading.");
    }
  };

  return (
    <div className="">
      <h1 className="alignCenter">
      North You Golf Course
      </h1>
      
      <div className="shadow">
        <div className="Gif-Area">
          <h4>{formatedDate}</h4>
          {url1 ? (
            <div className="centerAlign">
              <video
                onClick={handlePlay1}
                ref={videoRef1}
                className="videoShots"
                playsInline
                preload="metadata"
                >
                  <source src={url1+"#t=0.001"} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              {/*payment && subscription ?
                          <button className='playButton'> <a href={url1} target='_blank'>Download Video</a> </button>
                          :<button onClick={handlePlay1} className='playButton'>Play 5 Seconds</button>
                          <></>*/}
            </div>
          ) : (
            <div>LOADING...</div>
          )}
          {url2 ? (
            <div className="centerAlign">
              <video
                onClick={handlePlay2}
                ref={videoRef2}
                className="videoShots"
                playsInline
                preload="metadata"
              >
                <source src={url2+"#t=0.001"} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              {/*payment && subscription ?
                          <button className='playButton'> <a href={url2} target='_blank'>Download Video</a> </button>
                          :<button onClick={handlePlay2} className='playButton'>Play 5 Seconds</button>
                          <></>*/}
            </div>
          ) : (
            <div>LOADING...</div>
          )}
          {date != "" ? <CountdownTimer date={date} /> : <></>}
        </div>

        <div className="info-container">
          <p className="info-item">
            {/*{city}, {state}*/} North You Golf Course
          </p>
          <p className="info-item">{formatedDate}</p>
          
        </div>

        <button
          className="downloadButton"
          onClick={async () => {

            if(!payment){
              //<Navigate to={"/checkout/"+customerId} />
              window.location.href = "/checkout/" + customerId;  

              /*try {
                const response = await fetch('https://golf-backend.vercel.app/create-checkout-session2', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
                const session = await response.json();
                window.location.href = session.url;
                //const stripe = await loadStripe('pk_live_51NsYYgD0QuFNloR8yc0f2kyyFNKSk9YsthouSoblG4pjSIdmV4J84LFJJ0GGxdaykeNYMCdy2Q0JBxWc2f4PE4s500rUU9SMc1'); // Replace with your live public key
          
                // Redirect to the Stripe Checkout page
                const { error } = await stripe.redirectToCheckout({
                  sessionId: session.id,
                });


          
                if (error) {
                  console.error(error.message);
                  // Handle the error (e.g., display an error message to the user)
                }


              } catch (error) {
                console.error('Error:', error);
                // Handle the error (e.g., display an error message to the user)
              }*/


              return;        
            }


            const downloadUrls = [url1, url2];

            const createAndDownloadBlob = (url) => {
              fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                  const objectURL = URL.createObjectURL(blob);
                  const link = document.createElement("a");
                  link.href = objectURL;
                  link.download = "video.mp4"; // Set desired filename here
                  link.click();
                  URL.revokeObjectURL(objectURL);
                });
            };

            downloadUrls.forEach((url) => {
              createAndDownloadBlob(url);
            });
          }}
        >
          {payment ? "Download Videos" : "Donate and Download Videos"}
        </button>
      </div>
      <div className="purchaseContainer">

        <CharityCard
          key={0}
          name={charityData[0].name}
          image={charityData[0].image}
          description={charityData[0].description}
        />

      </div>
      

      <TestimonialSection/>
      <div className="purchaseContainer">
        <FAQ />
      </div>

    </div>
  );
}

export default PurchasePage;


/*
<div className="container">

        <div className="payment__info">
          {!payment ? (
            <div className="payment__cc">
              <form>
                  <div className="row">
                    <div className="field">
                      <div className="title">Credit Card Number</div>
                      <input
                        type="text"
                        className="input txt text-validated"
                        value="4542 9931 9292 2293"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="field small">
                      <div className="title">Expiry Date</div>
                      <select className="input ddl">
                        <option defaultValue>01</option>
                        <option>02</option>
                        </select>
                        <select className="input ddl">
                          <option>01</option>
                          <option>02</option>
                        </select>
                      </div>
                    </div>
  
                    <div className="row">
                      <div className="field small">
                        <div className="title">
                          CVV Code<span className="numberCircle">?</span>
                        </div>
                        <input type="text" className="input txt" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="field">
                        <div className="title">Name on Card</div>
                        <input type="text" className="input txt" />
                      </div>
                    </div>
                </form>
                {false ? (
                  <button
                    className="btn action__submit paymentBtn"
                    onClick={() => {
                      console.log("Payment done!!");
                    }}
                  >
                    Make Payment
                    <i className="icon icon-arrow-right-circle"></i>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          
          {subscription ? (
                <div className="payment__shipping">
                  <div className="payment__title">
                    <i className="icon icon-plane"></i> Subscription Information
                  </div>
                  <div className="field small">
                    <div className="title">Full Name</div>
                    <input
                      type="text"
                      className="input txt"
                      value={fullName}
                      onChange={handleFullNameChange}
                    />
                  </div>
  
                  <div className="field small">
                    <div className="title">Mail</div>
                    <input
                      type="text"
                      className="input txt"
                      value={mail}
                      onChange={handleMailChange}
                    />
                  </div>
  
                  <div className="field small">
                    <div className="title">Address</div>
                    <input
                      type="text"
                      className="input txt"
                      value={address}
                      onChange={handleAddressChange}
                    />
                  </div>
  
                  <div className="container">
                    <div className="actions">
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        I read and accepted the terms
                      </label>
                      <br />
                      {false?<button
                        className="btn action__submit"
                        onClick={handleDownloadClick}
                        disabled={!isChecked}
                      >
                        Subscribe Us
                        <i className="icon icon-arrow-right-circle"></i>
                      </button>:<></>}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
        </div>
*/