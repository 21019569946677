import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

import axios from 'axios';
const CheckoutButton = () => {



  const handleClick = async () => {
    try {
        const response = await axios.post('https://golf-backend.vercel.app/create-checkout-session2', {}, {
          headers: {
            'Content-Type': 'application/json',
          },
          proxy: {
            host: 'localhost',
            port: 3000
          }
        });
        const session = response.data;
        window.location.href = session.url;
       } catch (error) {
        console.error('Error:', error);
        // Handle the error (e.g., display an error message to the user)
       }
  };

  return (
    <button onClick={handleClick}>Checkout</button>
  );
};

export default CheckoutButton;