import React, { useState } from 'react';
//import './stat.css'; // Import your CSS file
import './Stat.css'; // Import your CSS file


function Statics() {
  const [selectedDropbox, setSelectedDropbox] = useState('');

  const handleDropboxSelect = (event) => {
    setSelectedDropbox(event.target.value);
  };

  return (
    <div className="stat-container">
      <form className="stat-form">
       
        <label className="stat-form-label">
          Region
          <select
            value={selectedDropbox}
            onChange={handleDropboxSelect}
            className="stat-form-input"
          >
            <option value="">Select a Dropbox</option>
            <option value="A">Option A</option>
            <option value="B">Option B</option>
            <option value="C">Option C</option>
          </select>
        </label>
        <label className="stat-form-label">
          Total SMS Send:{0}
        </label>
        <br/>
        <label className="stat-form-label">
          Total Payment Done:{0}
        </label>
        
      </form>
    </div>
  );
}

export default Statics;
