import React, { useState, useEffect } from 'react';
import './Countdown.css';

const CountdownTimer = ({date}) => {
  const calculateTimeRemaining = () => {
    const now = new Date(date);
    const targetDate = new Date(now.getTime() + (7 * 24 * 60 * 60 * 1000)); // 7 days in milliseconds
    return Math.max(0, Math.floor((targetDate - now) / 1000)); // Ensure countdown doesn't go negative
  }

  const [countdown, setCountdown] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(prevCountdown => Math.max(0, prevCountdown - 1));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const days = Math.floor(countdown / (24 * 60 * 60));
  const hours = Math.floor((countdown % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((countdown % (60 * 60)) / 60);
  const seconds = countdown % 60;

  return (
    <div className='containerCount'>
      {/*<h1 className='available'>Available</h1>
      <p className='countdown'>{days} days, {hours} hours, {minutes} minutes, {seconds} seconds</p>*/}

      <h4 className='countdown'>{days} days {hours} hours Left to download</h4>
    </div>
  );
};

export default CountdownTimer;
