import React, { useState, useEffect, useRef } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import {useParams} from "react-router-dom";
import CharityCard from './CharityCard'

import "./App.css";
import axios from 'axios';
import "../pages/Purchase.css";

const stripePromise = loadStripe("pk_live_51NsYYgD0QuFNloR8yc0f2kyyFNKSk9YsthouSoblG4pjSIdmV4J84LFJJ0GGxdaykeNYMCdy2Q0JBxWc2f4PE4s500rUU9SMc1");
const CheckoutForm = () => {
    const [clientSecret, setClientSecret] = useState('');
    const {id} = useParams();
    const charityData = [
      {
        name: "Your support will go towards helping First Tee",
        image: "CH1.jpg",
        description:
          "First Tee is a youth development organization that enables kids to build the strength of character that empowers them through a lifetime of new challenges.",
      },
      {
        name: "Charity B",
        image: "charity_b.jpg",
        description: "Description of Charity B",
      },
      // Add more charities as needed
    ];

    useEffect(() => {
        document.title = 'Golf Shot'; 

        localStorage.setItem("userId",id);
        // Create a Checkout Session as soon as the page loads
        axios.post("https://golf-backend.vercel.app/create-checkout-session", {}, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then((response) => {
            setClientSecret(response.data.clientSecret);
          })
          .catch((error) => {
            console.error('Error:', error);
          });

          axios
      .get(`https://golf-backend.vercel.app/customers/${id}`)
      .then((response) => {
        const { videourl1, videourl2 } = response.data;

        setUrl1(videourl1);
        setUrl2(videourl2);

        console.log("Video URL 1:", videourl1);
        console.log("Video URL 2:", videourl2);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching city and state:", error);
      });
  



    }, []);
  
    let videoRef1 = useRef(null);
    let videoRef2 = useRef(null);
    const [url1, setUrl1] = useState("");
    const [url2, setUrl2] = useState("");

    const handlePlay1 = () => {
      videoRef1.current.currentTime = 0; // Start from the beginning
      videoRef1.current.play();
      setTimeout(() => {
        videoRef1.current.pause();
      }, 5000); // Pause after 5 seconds
    };
  
    const handlePlay2 = () => {
      videoRef2.current.currentTime = 0; // Start from the beginning
      videoRef2.current.play();
      setTimeout(() => {
        videoRef2.current.pause();
      }, 5000); // Pause after 5 seconds
    };

    return (
      <div id="checkout">
        <h1 className="alignCenter">
              North You Golf Course
        </h1>
        <div className="shadow">
          <div className="Gif-Area">
            {url1 ? (
              <div className="centerAlign">
                <video
                  onClick={handlePlay1}
                  ref={videoRef1}
                  className="videoShots"
                >
                  <source src={url1+"#t=0.001"} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                {/*payment && subscription ?
                            <button className='playButton'> <a href={url1} target='_blank'>Download Video</a> </button>
                            :<button onClick={handlePlay1} className='playButton'>Play 5 Seconds</button>
                            <></>*/}
              </div>
            ) : (
              <div>LOADING...</div>
            )}
            {url2 ? (
              <div className="centerAlign">
                <video
                  onClick={handlePlay2}
                  ref={videoRef2}
                  className="videoShots"
                >
                  <source src={url2+"#t=0.001"} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                {/*payment && subscription ?
                            <button className='playButton'> <a href={url2} target='_blank'>Download Video</a> </button>
                            :<button onClick={handlePlay2} className='playButton'>Play 5 Seconds</button>
                            <></>*/}
              </div>
            ) : (
              <div>LOADING...</div>
            )}
          </div>


        </div>

        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{clientSecret}}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}

        <div className="purchaseContainer">

          <CharityCard
            key={0}
            name={charityData[0].name}
            image={charityData[0].image}
            description={charityData[0].description}
          />

        </div>
      </div>
    )
  }


  export default CheckoutForm;