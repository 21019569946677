import React, { useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  BrowserRouter as Router,
  Navigate
} from "react-router-dom";

import axios from 'axios';

import "./App.css";

const stripePromise = loadStripe("pk_live_51NsYYgD0QuFNloR8yc0f2kyyFNKSk9YsthouSoblG4pjSIdmV4J84LFJJ0GGxdaykeNYMCdy2Q0JBxWc2f4PE4s500rUU9SMc1");

const Return = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const [userId,setUserId] = useState("");
  
    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');

      const user = localStorage.getItem('userId');
      console.log(user);
      if(user){
        setUserId(user);
      }

      fetch(`https://golf-backend.vercel.app/session-status?session_id=${sessionId}`)
        .then((res) => res.json())
        .then(async (data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);

          if(data.status == 'complete'){

            try {
                const response = await axios.put(`https://golf-backend.vercel.app/updatePaymentInfo/${userId}`);
                return response.data;
              } catch (error) {
                console.error('Error updating PaymentInfo:', error);
                throw error; // You might want to handle this error in the calling function
              }

          }
        });
    }, []);
  
    if (status === 'open') {
      return (
        <Navigate to={"/checkout/"+userId} />
      )
    }
  
    if (status === 'complete') {
      return (
        <section id="success">
          <p>
            We appreciate your business! A confirmation email will be sent to {customerEmail}.
  
            If you have any questions, please email
            <a href="mailto:orders@example.com">orders@example.com</a>.
          </p>
        </section>
      )
    }
  
    return null;
  }
  
  export default Return;